import type {
  McpNotificationFragment,
  NotificationCategory,
} from '@wise/graphql'

import { WiseTrans } from '~shared/components/WiseTrans'

export const getNotificationCategories = (
  notifications: McpNotificationFragment[],
): McpNotificationFragment['category'][] => {
  const categories = new Set<McpNotificationFragment['category']>()
  notifications.forEach((notification) => {
    categories.add(notification.category)
  })
  return Array.from(categories)
}

export const formatNotificationCategory = (category: NotificationCategory) => (
  <WiseTrans i18nKey={`notifications.category.${category}`} />
)
