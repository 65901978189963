import type { TFuncKey } from 'i18next'
import * as React from 'react'
// eslint-disable-next-line @wise/enforce-wise-trans
import { Trans, type TransProps, useTranslation } from 'react-i18next'

type Props = TransProps<TFuncKey<'common'>, 'common'>

export const WiseTrans = React.memo<Props>((props) => {
  const { t } = useTranslation()

  return <Trans {...props} t={t} />
})

WiseTrans.displayName = 'WiseTrans'
