import type { ParsedFeatureFlags } from '@wise/kv'
import * as React from 'react'

import { FALLBACK_FEATURE_FLAGS } from '~shared/config/feature-flags'
import { useAuth } from '~shared/services/firebase/auth/hooks'

type UseFeatureFlagsArg = keyof ParsedFeatureFlags | undefined

export type UseFeatureFlagsReturn<
  T extends UseFeatureFlagsArg = keyof ParsedFeatureFlags,
> = T extends keyof ParsedFeatureFlags
  ? ParsedFeatureFlags[T]
  : ParsedFeatureFlags

export function useFeatureFlags<T extends UseFeatureFlagsArg = undefined>(
  name?: T,
): UseFeatureFlagsReturn<T>
export function useFeatureFlags(
  name?: UseFeatureFlagsArg,
): UseFeatureFlagsReturn<undefined> | UseFeatureFlagsReturn {
  const auth = useAuth()
  const featureFlags = React.useMemo(
    () => auth.data?.featureFlags ?? FALLBACK_FEATURE_FLAGS,
    [auth.data?.featureFlags],
  )

  return name ? featureFlags[name] : featureFlags
}
