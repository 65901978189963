import { MainContractor, MainContractorStatus } from '@wise/graphql'
import * as React from 'react'

import { route } from '~generated/routes'
import OnboardingUserIcon from '~shared/components/Icons/svg/add-user.svg'
import UtrIcon from '~shared/components/Icons/svg/background-check.svg'
import ContractIcon from '~shared/components/Icons/svg/contract-icon.svg'
import DocumentIcon from '~shared/components/Icons/svg/documents.svg'
import ActiveUserIcon from '~shared/components/Icons/svg/driver.svg'
import EvidenceIcon from '~shared/components/Icons/svg/evidence.svg'
import InactiveUserIcon from '~shared/components/Icons/svg/exclamation-triangle.svg'
import HourglassIcon from '~shared/components/Icons/svg/hourglass.svg'
import IdCardIcon from '~shared/components/Icons/svg/id-card.svg'
import VanIcon from '~shared/components/Icons/svg/main-contractor.svg'
import RateCardIcon from '~shared/components/Icons/svg/payment-details.svg'
import MoneyIcon from '~shared/components/Icons/svg/payments.svg'
import PoundIcon from '~shared/components/Icons/svg/pound.svg'
import DashboardIcon from '~shared/components/Icons/svg/reports.svg'
import RightToBracketIcon from '~shared/components/Icons/svg/right-to-bracket.svg'
import SettingsIcon from '~shared/components/Icons/svg/settings.svg'
import SquarePollIcon from '~shared/components/Icons/svg/square-poll-vertical.svg'
import TagsIcon from '~shared/components/Icons/svg/tags.svg'
import TickInBoxIcon from '~shared/components/Icons/svg/tick-in-box.svg'
import TickIcon from '~shared/components/Icons/svg/tick.svg'
import UserIcon from '~shared/components/Icons/svg/user.svg'
import WorkHistoryIcon from '~shared/components/Icons/svg/work-history.svg'
import {
  type UseFeatureFlagsReturn,
  useFeatureFlags,
} from '~shared/featureFlags'
import {
  CAN_VIEW_CUSTOMERS_LIST,
  CAN_VIEW_MAINCONTRACTORS_LIST,
  CAN_VIEW_PAYMENTS,
  CAN_VIEW_PAYMENTS_DASHBOARD,
  CAN_VIEW_SCHEDULING,
  CAN_VIEW_USERS_LIST,
} from '~shared/hooks/gatekeeper/checks'
import {
  GatekeeperCheck,
  gatekeeperForUser,
} from '~shared/hooks/gatekeeper/useGatekeeper'
import { PortalMode, useMode } from '~shared/hooks/useMode'
import { TranslationKey } from '~shared/i18n/i18n'
import {
  useUser,
  useUserPermissions,
} from '~shared/services/firebase/auth/hooks'

import {
  UseMainContractorReturn,
  useMaincontractor,
} from '@/maincontractors/hooks/useMaincontractor'

export type NavDropdownConfig = {
  sections: {
    title?: TranslationKey
    options: {
      title: TranslationKey
      description: TranslationKey
      url: string
      queryParams?: string[]
      size?: '1/4' | '1/2' | 'full'
      icon?: React.ComponentType<JSX.IntrinsicElements['svg']>
      activePath?: string | RegExp
      mcStatus?: MainContractorStatus
      mcCheck?: (mc: UseMainContractorReturn) => boolean
      modes?: PortalMode[]
      featureFlag?: keyof UseFeatureFlagsReturn<undefined>
      gatekeeper?: GatekeeperCheck
    }[]
  }[]
}

type SharedNavOptions = {
  id: string
  title: TranslationKey
  disabled?: boolean
  activePath?: string | RegExp
  featureFlag?: keyof UseFeatureFlagsReturn<undefined>
  mcStatus?: Mandatory<MainContractor['status']>
  gatekeeper?: GatekeeperCheck
}

export type NavOption = SharedNavOptions &
  (
    | {
        type: 'direct-link'
        url: string
        queryParams?: string[]
      }
    | { type: 'dropdown'; config: NavDropdownConfig }
  )

type RouteTypes =
  | 'Compliance'
  | 'Customers'
  | 'Dashboard'
  | 'Drivers'
  | 'EvidenceTemplateLibrary'
  | 'KnowledgeBase'
  | 'MainContractors'
  | 'PaymentsDashboard'
  | 'PaymentsMCP'
  | 'PaymentsSchedule'
  | 'Reports'
  | 'Scheduling'
  | 'Settings'
  | 'Users'
  | 'Worklog'
  | 'NetworkResource'

const Options: Record<RouteTypes, NavOption> = {
  Compliance: {
    id: 'compliance',
    type: 'dropdown',
    title: 'side_nav.compliance',
    mcStatus: 'ACTIVE',
    gatekeeper: CAN_VIEW_USERS_LIST,
    activePath: '/compliance',
    config: {
      sections: [
        {
          options: [
            {
              title: 'compliance.driving_licence_title',
              description: 'compliance.driving_licence_description',
              url: route('/compliance/driving-licence'),
              activePath: '/compliance/driving-licence',
              featureFlag: 'drivingLicenceChecksEnabled',
              icon: IdCardIcon,
            },
            {
              title: 'reports.driver_evidence_title',
              description: 'reports.driver_evidence_description',
              url: route('/compliance/evidence'),
              activePath: '/compliance/evidence',
              featureFlag: 'expiryHubEnabled',
              icon: EvidenceIcon,
            },
          ],
        },
      ],
    },
  },
  Customers: {
    id: 'customers',
    type: 'direct-link',
    url: route('/customers'),
    title: 'side_nav.customers',
    activePath: '/customers',
    gatekeeper: CAN_VIEW_CUSTOMERS_LIST,
  },
  Dashboard: {
    id: 'dashboard',
    type: 'direct-link',
    url: route('/'),
    title: 'side_nav.dashboard',
    activePath: /^\/$/,
  },
  EvidenceTemplateLibrary: {
    id: 'evidence-template-library',
    type: 'dropdown',
    title: 'side_nav.evidence_template_library.title',
    activePath: /^\/evidence-template\//,
    config: {
      sections: [
        {
          options: [
            {
              title: 'side_nav.evidence_template_library.template_title',
              description:
                'side_nav.evidence_template_library.template_description',
              url: route('/evidence-template/templates'),
              icon: DocumentIcon,
            },
            {
              title: 'side_nav.evidence_template_library.labels_title',
              description:
                'side_nav.evidence_template_library.labels_description',
              url: route('/evidence-template/labels'),
              icon: TagsIcon,
            },
          ],
        },
      ],
    },
  },
  KnowledgeBase: {
    id: 'knowledge-base',
    type: 'direct-link',
    url: '/knowledge-base',
    title: 'side_nav.knowledge_base',
    mcStatus: 'ACTIVE',
  },
  MainContractors: {
    id: 'main-contractors',
    type: 'dropdown',
    title: 'side_nav.main_contractors.title',
    activePath: '/main-contractors',
    gatekeeper: CAN_VIEW_MAINCONTRACTORS_LIST,
    config: {
      sections: [
        {
          options: [
            {
              description: 'side_nav.main_contractors.active_description',
              title: 'side_nav.main_contractors.active_title',
              url: route('/main-contractors'),
              activePath: /^\/main-contractors\/?$/,
              icon: VanIcon,
            },
            {
              description: 'side_nav.main_contractors.onboarding_description',
              title: 'side_nav.main_contractors.onboarding_title',
              url: route('/main-contractors/onboarding'),
              activePath: '/main-contractors/onboarding',
              icon: VanIcon,
            },
            {
              description: 'side_nav.main_contractors.inactive_description',
              title: 'side_nav.main_contractors.inactive_title',
              url: route('/main-contractors/inactive'),
              activePath: '/main-contractors/inactive',
              icon: VanIcon,
            },
            {
              description: 'side_nav.main_contractors.archived_description',
              title: 'side_nav.main_contractors.archived_title',
              url: route('/main-contractors/archived'),
              activePath: '/main-contractors/archived',
              icon: VanIcon,
            },
          ],
        },
      ],
    },
  },
  NetworkResource: {
    id: 'network-resource',
    type: 'dropdown',
    config: {
      sections: [
        {
          title: 'side_nav.network_resource.direct_title',
          options: [
            {
              description:
                'side_nav.network_resource.direct_available_description',
              title: 'side_nav.network_resource.direct_available_title',
              url: route('/resource/direct/available'),
            },
            {
              description:
                'side_nav.network_resource.direct_onboarding_description',
              title: 'side_nav.network_resource.direct_onboarding_title',
              url: route('/resource/direct/onboarding'),
            },
            {
              description:
                'side_nav.network_resource.direct_offboarded_description',
              title: 'side_nav.network_resource.direct_offboarded_title',
              url: route('/resource/direct/offboarded'),
            },
          ],
        },
        {
          title: 'side_nav.network_resource.partners_title',
          options: [
            {
              description:
                'side_nav.network_resource.partners_available_description',
              title: 'side_nav.network_resource.partners_available_title',
              url: route('/resource/partners/available'),
            },
            {
              description:
                'side_nav.network_resource.partners_onboarding_description',
              title: 'side_nav.network_resource.partners_onboarding_title',
              url: route('/resource/partners/onboarding'),
            },
            {
              description:
                'side_nav.network_resource.partners_disengaged_description',
              title: 'side_nav.network_resource.partners_disengaged_title',
              url: route('/resource/partners/disengaged'),
            },
          ],
        },
      ],
    },
    title: 'side_nav.network_resource.title',
    activePath: '/resource',
  },
  Drivers: {
    id: 'my-drivers',
    type: 'dropdown',
    title: 'side_nav.my_drivers',
    activePath: '/drivers/',
    mcStatus: 'ACTIVE',
    gatekeeper: CAN_VIEW_USERS_LIST,
    config: {
      sections: [
        {
          options: [
            {
              title: 'side_nav.drivers_dropdown.active_title',
              description: 'side_nav.drivers_dropdown.active_description',
              url: route('/drivers/active'),
              queryParams: ['query', 'depots', 'customers', 'product'],
              icon: ActiveUserIcon,
            },
            {
              title: 'side_nav.drivers_dropdown.onboarding_title',
              description: 'side_nav.drivers_dropdown.onboarding_description',
              url: route('/drivers/onboarding'),
              queryParams: ['query', 'depots', 'appInstalled', 'customers'],
              icon: OnboardingUserIcon,
            },
            {
              title: 'side_nav.drivers_dropdown.inactive_title',
              description: 'side_nav.drivers_dropdown.inactive_description',
              url: route('/drivers/inactive'),
              queryParams: ['query', 'reason'],
              icon: InactiveUserIcon,
            },
          ],
        },
      ],
    },
  },
  PaymentsMCP: {
    id: 'payments-dashboard',
    type: 'direct-link',
    url: '/payments',
    title: 'side_nav.payments.title',
    activePath: '/payments',
    mcStatus: 'ACTIVE',
    gatekeeper: CAN_VIEW_PAYMENTS,
  },
  PaymentsDashboard: {
    id: 'payments-dashboard',
    type: 'dropdown',
    // url: '/payments',
    title: 'side_nav.payments.title',
    activePath: '/payments',
    mcStatus: 'ACTIVE',
    gatekeeper: CAN_VIEW_PAYMENTS,
    config: {
      sections: [
        {
          options: [
            {
              description: 'side_nav.payments.dashboard_description',
              title: 'side_nav.payments.dashboard_title',
              url: route('/payments'),
              activePath: /\/payments\/?$/,
              gatekeeper: CAN_VIEW_PAYMENTS,
              icon: DashboardIcon,
            },
            {
              description: 'side_nav.payments.in-progress_description',
              title: 'side_nav.payments.in-progress_title',
              url: route('/payments/in-progress'),
              gatekeeper: CAN_VIEW_PAYMENTS,
              icon: HourglassIcon,
            },
            {
              description: 'side_nav.payments.submitted_description',
              title: 'side_nav.payments.submitted_title',
              url: route('/payments/submitted'),
              gatekeeper: CAN_VIEW_PAYMENTS,
              icon: RightToBracketIcon,
            },
            {
              description: 'side_nav.payments.completed_description',
              title: 'side_nav.payments.completed_title',
              url: route('/payments/completed'),
              gatekeeper: CAN_VIEW_PAYMENTS,
              icon: TickIcon,
            },
            {
              description: 'side_nav.payments.closed_description',
              title: 'side_nav.payments.closed_title',
              url: route('/payments/closed'),
              gatekeeper: CAN_VIEW_PAYMENTS,
              icon: TickInBoxIcon,
            },
          ],
        },
      ],
    },
  },
  PaymentsSchedule: {
    id: 'payments-schedule',
    type: 'direct-link',
    url: '/payments/in-progress',
    title: 'side_nav.payment_schedule',
    gatekeeper: { NOT: CAN_VIEW_PAYMENTS_DASHBOARD },
  },
  Scheduling: {
    id: 'scheduling',
    type: 'direct-link',
    url: '/scheduling',
    title: 'side_nav.scheduling',
    featureFlag: 'schedulingEnabled',
    mcStatus: 'ACTIVE',
    gatekeeper: CAN_VIEW_SCHEDULING,
  },
  Reports: {
    id: 'reports',
    type: 'dropdown',
    title: 'side_nav.reports',
    mcStatus: 'ACTIVE',
    gatekeeper: CAN_VIEW_USERS_LIST,
    activePath: '/reports',
    config: {
      sections: [
        {
          options: [
            {
              title: 'reports.signed_sla_title',
              description: 'reports.signed_sla_description',
              url: route('/reports/slas'),
              activePath: '/reports/slas',
              size: '1/2',
              icon: ContractIcon,
            },
            {
              title: 'reports.signed-policies-title',
              description: 'reports.signed-policies-description',
              url: route('/reports/signed-policies'),
              activePath: '/reports/signed-policies',
              size: '1/2',
              featureFlag: 'signedPoliciesReportEnabled',
              icon: DocumentIcon,
            },
            {
              title: 'reports.unresponsive_applications_title',
              description: 'reports.unresponsive_applications_description',
              url: route('/reports/unresponsive-applications'),
              activePath: '/reports/unresponsive-applications',
              size: '1/2',
              icon: WorkHistoryIcon,
            },
            {
              title: 'reports.awaiting_activation_title',
              description: 'reports.awaiting_activation_description',
              url: route('/reports/awaiting-activation'),
              activePath: '/reports/awaiting-activation',
              size: '1/2',
              icon: OnboardingUserIcon,
            },
            {
              title: 'reports.driver_depot_title',
              description: 'reports.driver_depot_description',
              url: route('/reports/driver-depots'),
              activePath: '/reports/driver-depots',
              size: '1/2',
              icon: VanIcon,
            },
            {
              title: 'reports.utr_title',
              description: 'reports.utr_description',
              url: route('/reports/utr-report'),
              activePath: '/reports/utr-report',
              size: '1/2',
              icon: UtrIcon,
            },
            {
              title: 'reports.deductions_overview_title',
              description: 'reports.deductions_overview_description',
              url: route('/reports/deductions-overview'),
              activePath: '/reports/deductions-overview',
              size: '1/2',
              featureFlag: 'deductionsEnabled',
              icon: PoundIcon,
            },
            {
              title: 'reports.payable_drivers.title',
              description: 'reports.payable_drivers.description',
              url: route('/reports/payable-drivers'),
              activePath: '/reports/payable-drivers',
              mcCheck: (mc) => !mc,
              modes: ['WAP'],
              size: '1/2',
              icon: SquarePollIcon,
            },
          ],
        },
      ],
    },
  },
  Settings: {
    id: 'settings',
    type: 'dropdown',
    title: 'side_nav.settings',
    activePath: '/settings',
    config: {
      sections: [
        {
          options: [
            {
              description: 'mcp_settings.tabs.general_settings_description',
              title: 'mcp_settings.tabs.general_settings',
              url: route('/settings'),
              activePath: /^\/settings\/?$/,
              icon: SettingsIcon,
            },
            {
              description: 'mcp_settings.tabs.carrier_settings_description',
              title: 'mcp_settings.tabs.carrier_settings',
              url: route('/settings/carrier-settings'),
              activePath: '/settings/carrier-settings',
              mcStatus: 'ACTIVE',
              featureFlag: 'worklogEnabled',
              icon: VanIcon,
            },
            {
              description: 'mcp_settings.tabs.rate_cards_description',
              title: 'mcp_settings.tabs.rate_cards',
              url: route('/settings/rate-cards'),
              activePath: '/settings/rate-cards',
              mcStatus: 'ACTIVE',
              featureFlag: 'worklogEnabled',
              icon: RateCardIcon,
            },
            {
              description: 'mcp_settings.tabs.payment_details_description',
              title: 'mcp_settings.tabs.payment_details',
              url: route('/settings/payments'),
              activePath: '/settings/payments',
              mcStatus: 'ACTIVE',
              icon: MoneyIcon,
            },
            {
              description:
                'mcp_settings.tabs.driving_licence_checks_description',
              title: 'mcp_settings.tabs.driving_licence_checks',
              url: route('/settings/driving-licence-checks'),
              activePath: '/settings/driving-licence-checks',
              mcStatus: 'ACTIVE',
              featureFlag: 'drivingLicenceChecksEnabled',
              icon: TickInBoxIcon,
            },
            {
              description: 'mcp_settings.tabs.user_management_description',
              title: 'mcp_settings.tabs.user_management',
              url: route('/settings/users'),
              activePath: '/settings/users',
              gatekeeper: {
                legacyPermission: 'mainContractor.user.list',
              },
              icon: UserIcon,
            },
            {
              description: 'mcp_settings.tabs.document_library_description',
              title: 'mcp_settings.tabs.document_library',
              url: route('/settings/document-library'),
              activePath: '/settings/document-library',
              mcStatus: 'ONBOARDING',
            },
            {
              description: 'mcp_settings.tabs.payment_caps_description',
              title: 'mcp_settings.tabs.payment_caps',
              url: route('/settings/payment-caps'),
              activePath: '/settings/payment-caps',
              mcStatus: 'ACTIVE',
              featureFlag: 'deductionsEnabled',
              mcCheck: (mc) => mc?.deductionEnabled ?? false,
              icon: PoundIcon,
            },
          ],
        },
      ],
    },
  },
  Users: {
    id: 'users',
    type: 'direct-link',
    url: '/users',
    title: 'side_nav.users',
    mcStatus: 'ACTIVE',
  },
  Worklog: {
    id: 'worklog',
    type: 'direct-link',
    url: '/worklog',
    title: 'side_nav.worklog',
    mcStatus: 'ACTIVE',
    featureFlag: 'worklogEnabled',
  },
}

const NETWORK_OPTIONS: NavOption[] = [
  Options.Dashboard,
  Options.NetworkResource,
  Options.KnowledgeBase,
]

const MCP_OPTIONS: NavOption[] = [
  Options.Dashboard,
  Options.Drivers,
  Options.Compliance,
  Options.PaymentsMCP,
  Options.Scheduling,
  Options.Worklog,
  Options.Reports,
  Options.Settings,
  Options.KnowledgeBase,
]

const WAP_OPTIONS: NavOption[] = [
  Options.Dashboard,
  Options.Drivers,
  Options.Compliance,
  Options.MainContractors,
  Options.Customers,
  Options.Scheduling,
  Options.Worklog,
  Options.Reports,
  Options.PaymentsDashboard,
  Options.PaymentsSchedule,
  Options.Users,
  Options.KnowledgeBase,
  Options.EvidenceTemplateLibrary,
]

export const useSidenavOptions = (): NavOption[] => {
  const user = useUser()
  const mc = useMaincontractor()
  const mode = useMode()
  const userPermission = useUserPermissions()
  const options = React.useMemo(() => {
    switch (mode) {
      case 'MCP':
        return MCP_OPTIONS
      case 'NAP':
        return NETWORK_OPTIONS
      case 'WAP':
        return WAP_OPTIONS
      case 'undetermined':
        return []
    }
  }, [mode])
  const featureFlags = useFeatureFlags()

  return options
    .filter((option) => {
      // Status check
      if (mode === 'MCP' && option.mcStatus && mc?.status !== option.mcStatus)
        return false

      // Feature flag check
      if (option.featureFlag) {
        return featureFlags[option.featureFlag]
      }

      // Gatekeeper check
      if (
        option.gatekeeper &&
        !gatekeeperForUser(option.gatekeeper, user, userPermission)
      ) {
        return false
      }

      return true
    })
    .map<NavOption>((o) => {
      if (o.type !== 'dropdown') return o

      const sections = o.config.sections
        .map<NavDropdownConfig['sections'][number]>((section) => {
          return {
            ...section,
            options: section.options.filter((c) => {
              // Status check
              if (mode === 'MCP' && c.mcStatus && mc?.status !== c.mcStatus)
                return false

              // Feature flag check
              if (c.featureFlag && !featureFlags[c.featureFlag]) return false

              // Modes check
              if (c?.modes?.includes('WAP') === false) return false

              // Gatekeeper check
              if (
                c.gatekeeper &&
                !gatekeeperForUser(c.gatekeeper, user, userPermission)
              )
                return false

              // Custom MC check
              if (c.mcCheck && !c.mcCheck(mc)) return false

              return true
            }),
          }
        })
        .filter((s) => s.options.length > 0)

      return { ...o, config: { sections } }
    })
}
