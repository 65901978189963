import { randomString } from '@wise/utils'
import cx from 'classnames'
import { AnimatePresence, motion, useSpring, useTransform } from 'framer-motion'
import * as React from 'react'

import { LeftOverlayPortal } from '~shared/components/Inlet/LeftOverlay'
import { RightPanelPortal } from '~shared/components/Inlet/RightPanel'
import Loading from '~shared/components/Loading/Loading'
import WithWiseFooter from '~shared/components/WithWiseFooter/WithWiseFooter'

import PathTitle from './PathTitle'
import TopNav from './TopNav'

interface Props {
  className?: string
  id?: string
  children?: React.ReactNode
  loading?: boolean
  colour?: string
  paths?: string[]
}

let dashboardId: string | undefined = undefined

const Dashboard = ({
  className,
  id: providedId,
  children,
  loading,
  colour,
  paths,
}: Props): JSX.Element => {
  const id = React.useMemo(() => providedId ?? randomString(), [providedId])

  React.useEffect(() => {
    dashboardId = id
  }, [id])

  const diff = React.useMemo(() => {
    const diff = id !== dashboardId
    return id === undefined ? true : diff
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const colourClass = React.useMemo(() => {
    return colour ?? 'bg-grey'
  }, [colour])

  const option = useSpring(diff ? 0 : 1)
  const opacity = useTransform(option, [0, 1], [0, 1])
  const translateY = useTransform(option, [0, 1], [-10, 0])

  React.useEffect(() => {
    if (diff) option.set(1)
  }, [diff, option])

  return (
    <div className='relative flex w-full min-w-[min-content] flex-col xl:w-full'>
      <TopNav />
      <div
        className={`relative flex min-h-full w-full flex-row pt-3 ${colourClass}`}
      >
        <LeftOverlayPortal.Target className='pointer-events-none absolute top-0 left-0 z-40 h-full' />
        <div
          className={cx(
            'mx-auto flex min-h-full max-w-[2200px] flex-1 flex-col py-3 px-6',
            colourClass,
            className,
          )}
        >
          <motion.div
            style={{ opacity, translateY }}
            className='flex flex-1 flex-col'
          >
            {paths ? <PathTitle paths={paths} /> : null}
            {children}
            <WithWiseFooter />
          </motion.div>
        </div>
        <RightPanelPortal.Target className='sticky top-0 right-0 h-screen shadow-md' />
        <AnimatePresence>
          {loading ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className='absolute inset-0 z-20 flex items-center justify-center bg-black/20 shadow-lg'
            >
              <Loading colour='white' />
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default React.memo(Dashboard)
