import { motion } from 'framer-motion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import DefaultIcon from '~shared/components/Icons/svg/wise-logo.svg'
import { NavDropdownConfig } from '~shared/permissions/sidenav'
import { isOnActivePath } from '~shared/utils/activePath'
import { getTabHref } from '~shared/utils/routing'

const DropdownItem = ({
  title,
  description,
  url,
  queryParams,
  onNavigate,
  activePath,
  icon,
  size = 'full',
  ix,
}: NavDropdownConfig['sections'][number]['options'][number] & {
  ix: number
  onNavigate: () => void
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  const isActive = isOnActivePath(activePath ?? url, router.pathname)

  const Icon = icon ?? DefaultIcon

  const relativeUrl =
    getTabHref({ filters: queryParams ?? [], href: url }, router.query) ?? url

  return (
    <Link href={relativeUrl}>
      <a
        tabIndex={0}
        className={twMerge(
          'group',
          isActive && 'from-theme to-theme-light',
          size === '1/4' && 'col-span-1',
          size === '1/2' && 'col-span-2',
          size === 'full' && 'col-span-full',
          'hover:bg-grey-100 focus:bg-grey-100',
        )}
        onClick={onNavigate}
      >
        <motion.div
          transition={{ delay: ix * 0.05 }}
          initial={{ opacity: 0, translateX: -20 }}
          animate={{ opacity: 1, translateX: 0 }}
          className='inline-flex h-full w-full cursor-pointer flex-row items-stretch rounded-[8px] bg-gradient-to-tr group-hover:bg-grey-100 group-focus:bg-grey-100'
        >
          <div className='m-[1px] flex w-full flex-row items-start gap-4 rounded-[7px] bg-white p-3 group-hover:bg-grey-100 group-focus:bg-grey-100'>
            <div
              className={twMerge(
                'flex h-10 w-10 flex-shrink-0 items-center justify-center self-center rounded-md bg-black/5 transition-colors group-hover:bg-white group-focus:bg-white',
                isActive ? 'text-theme-light' : 'text-black/25',
                'group-hover:text-theme-light group-focus:text-theme-light',
                'group-hover:shadow-glow group-focus:shadow-glow',
              )}
            >
              <Icon
                className={twMerge(
                  'group-hover:fill-theme-light',
                  isActive ? 'fill-theme-light' : 'fill-black/25',
                )}
              />
            </div>
            <div className='flex flex-col'>
              <div>
                <p
                  className={twMerge(
                    'inline-block font-bold',
                    isActive &&
                      'bg-gradient-to-r from-theme to-theme-light bg-clip-text text-transparent',
                    'group-hover:bg-gradient-to-r group-hover:from-theme group-hover:to-theme-light group-hover:bg-clip-text group-hover:text-transparent',
                    'group-focus:bg-gradient-to-r group-focus:from-theme group-focus:to-theme-light group-focus:bg-clip-text group-focus:text-transparent',
                  )}
                >
                  {t(title)}
                </p>
              </div>
              <p className='max-w-400 text-xs font-light'>{t(description)}</p>
            </div>
          </div>
        </motion.div>
      </a>
    </Link>
  )
}

type Props = {
  config: NavDropdownConfig
  onNavigate: () => void
}

const DropdownContents = ({ config, onNavigate }: Props) => {
  const { t } = useTranslation()

  return (
    <div className='flex flex-row items-start gap-3 p-3'>
      {config.sections.map((section, six) => (
        <div key={six} className='flex flex-col'>
          {section.title ? (
            <h3 className='px-3 py-5 font-bold'>{t(section.title)}</h3>
          ) : null}
          <div className='grid w-max min-w-400 max-w-[90vw] grid-cols-4 gap-1'>
            {section.options.map((item, iix) => (
              <DropdownItem
                key={iix}
                {...item}
                ix={iix}
                onNavigate={onNavigate}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default DropdownContents
